<template>
  <div>
    <router-link
      :to="`/societa/dettaglio-societa/impianti-societa/${id_societa}`"
      class="btn btn-light text-gray-700 fs-6 text-start"
    >
      <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista impianti società
    </router-link>
  </div>
  <br />
  <DetailImpianto :impiantoSocieta="impiantoSocieta" :onlyView="onlyView" />
</template>

<script>
import DetailImpianto from "@/views/impianti/DetailImpianto.vue";
import { useRoute } from "vue-router";
import { ref } from "vue";

export default {
  components: { DetailImpianto },
  setup() {
    const route = useRoute();
    const id_societa = ref(route.params.id);

    const impiantoSocieta = ref(true);

    return {
      id_societa,
      impiantoSocieta,
    };
  },
};
</script>
